@font-face {
  font-family: HelvNeueOrange;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/HelvNeue55_W1G.woff2") format("woff2"), url("../fonts/HelvNeue55_W1G.woff") format("woff");
}

@font-face {
  font-family: HelvNeueOrange;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/HelvNeue75_W1G.woff2") format("woff2"), url("../fonts/HelvNeue75_W1G.woff") format("woff");
}

/*# sourceMappingURL=orangeHelvetica.css.map */